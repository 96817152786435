import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useClient } from 'urql';
import { navigate } from 'gatsby-plugin-intl';
import * as styles from './LoanPredictor.module.scss';
import {
  AccomodationType,
  AuthStatus,
  Children,
  LoanPredictionDocument,
  LoanPredictionModelVersionDetails,
  LoanPredictionMutation,
  LoanPredictionMutationVariables,
  MaritalStatus,
  OccupationTypeName,
  useGetAuthStatusQuery,
  useInitAuthMutation,
  useIsJwtValidQuery,
} from '../../../graphql/generated';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import {
  accommodations,
  occupations,
  maritalStatus as maritalStatusOptions,
} from '@src/lib/options';
import CircularProgress from '@mui/material/CircularProgress';
import {
  isSomeEnum,
  loanPredictionDefaultVersionDetails,
  tokenIsExpired,
  formatMoney,
} from '@src/lib/utils';
import {
  Button,
  Typography,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import YesNoInput from '../forms/YesNoInput';
import loanApplicationSlice, {
  LoanApplicationReduxType,
} from '../../redux/loanApplication';
import appSlice, { AppState } from '@src/redux/app';
import { isIOS } from 'react-device-detect';
import QRCode from 'react-qr-code';
import Markdown from '../markdown';
import * as ThirdPartyTracking from '@lib/thirdPartyTracking';
import { loanPredictionAttributes } from '../app/LoanPredictionResult/utils';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import useLoanPredictionModelVersionWithCache from '@src/hooks/useLoanPredictionModelVersionWithCache.hook';
import { isValidEmail, isValidPhoneNumber } from '@src/lib/validationUtils';
import { AsYouType } from 'libphonenumber-js';

type FormErrors = {
  accommodationType?: boolean;
  maritalStatus?: boolean;
  occupationType?: boolean;
  monthlyIncome?: boolean;
  renegotiateLoansAmount?: boolean;
  totalExistingDebts?: boolean;
  phone?: boolean;
  email?: boolean;
};

type Props = {
  updateAppliedAmount: (number) => void;
  updateAmortizationPeriod: (number) => void;
  updateAccommodation: (AccomodationType) => void;
  updateMaritalStatus: (MaritalStatus) => void;
  updateNumberOfChildren: (number) => void;
  updateOccupation: (OccupationTypeName) => void;
  updateMonthlySalary: (number) => void;
  updateRenegotiateLoansAmount: (number) => void;
  updateRenegotiateLoans: (boolean) => void;
  updateEmail: (string) => void;
  updatePhone: (string) => void;
  loanApplication: {
    appliedAmount: number;
    amortizationPeriod: number;
    accommodation: AccomodationType;
    maritalStatus: MaritalStatus;
    numberOfChildren: number;
    occupation: OccupationTypeName;
    monthlySalary: number;
    renegotiateLoansAmount: number;
    renegotiateLoans: boolean;
    email: string;
    phone: string;
  };
  app: AppState;
  setLoggedIn: () => void;
  setMemberId: (memberId: string) => void;
};

type MobileBankIdAuthState =
  | 'not_started'
  | 'started'
  | 'started_mobile'
  | 'canceled'
  | 'completed';

const LoanPredictor = (props: Props) => {
  const urqlClient = useClient();

  const asYouType = new AsYouType('SE');

  const token =
    typeof window === 'undefined' ? null : window.localStorage.getItem('token');
  const [customClaims, isJwtValid] = useIsJwtValidQuery({
    pause: !token || tokenIsExpired(token),
  });

  const loanPredictorModelVersion:
    | LoanPredictionModelVersionDetails
    | undefined = useLoanPredictionModelVersionWithCache();

  const defaultVersionAccuracy = `${loanPredictorModelVersion?.accuracy ??
    loanPredictionDefaultVersionDetails.accuracy}`.replace('.', ',');
  const defaultVersionReleaseDate =
    loanPredictorModelVersion?.releaseDate ??
    loanPredictionDefaultVersionDetails.releaseDate;

  const accommodationType: AccomodationType | null = Object.values(
    AccomodationType,
  ).includes(props.loanApplication.accommodation)
    ? props.loanApplication.accommodation
    : null;
  const occupationType: OccupationTypeName | null = Object.values(
    OccupationTypeName,
  ).includes(props.loanApplication.occupation)
    ? props.loanApplication.occupation
    : null;
  const monthlyIncome = props.loanApplication.monthlySalary;
  const maritalStatus: MaritalStatus | null = Object.values(
    MaritalStatus,
  ).includes(props.loanApplication.maritalStatus)
    ? props.loanApplication.maritalStatus
    : null;
  const [children, setChildren] = useState<Children>(
    props.loanApplication.numberOfChildren
      ? Children.OneChild
      : Children.NoChildren,
  );
  const [paymentRemark, setPaymentRemark] = useState<boolean>(false);
  const renegotiateLoans = props.loanApplication.renegotiateLoans;
  const [totalExistingDebts, setTotalExistingDebts] = useState<number | null>(
    props.loanApplication.renegotiateLoansAmount > 10_000
      ? props.loanApplication.renegotiateLoansAmount
      : null,
  );
  const [renegotiateLoansAmount, setRenegotiateLoansAmount] = useState<
    number | null
  >(
    props.loanApplication.renegotiateLoansAmount > 10_000
      ? props.loanApplication.renegotiateLoansAmount
      : null,
  );
  const phone = props.loanApplication.phone;
  const email = props.loanApplication.email;
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [showQuotaDialog, setShowQuotaDialog] = useState<boolean>(false);

  const validateForm = (): FormErrors => {
    return {
      accommodationType: !accommodationType,
      maritalStatus: !maritalStatus,
      occupationType: !occupationType,
      monthlyIncome:
        !monthlyIncome || monthlyIncome < 10_000 || monthlyIncome > 140_000,
      renegotiateLoansAmount:
        renegotiateLoans &&
        (renegotiateLoansAmount < 0 || renegotiateLoansAmount > 600_000),
      totalExistingDebts:
        renegotiateLoans &&
        (totalExistingDebts > 3_000_000 ||
          (renegotiateLoansAmount &&
            totalExistingDebts < renegotiateLoansAmount)),
      phone: !isValidPhoneNumber(phone),
      email: !isValidEmail(email),
    };
  };

  const validateSingleField = (k: keyof FormErrors): FormErrors => {
    const newErrors = validateForm();
    return { ...errors, [k]: newErrors[k] };
  };

  const onSubmit = async () => {
    window.scrollTo(0, 0);
    const newErrors = validateForm();
    setErrors(newErrors);
    if (!Object.values(newErrors).includes(true)) {
      setLoading(true);
      try {
        const { data } = await urqlClient
          .mutation<LoanPredictionMutation, LoanPredictionMutationVariables>(
            LoanPredictionDocument,
            {
              request: {
                maritalStatus,
                children,
                accommodationType,
                occupationType,
                monthlyIncome,
                paymentRemark,
                existingLoansToConsolidate: renegotiateLoans
                  ? renegotiateLoansAmount || 0
                  : 0,
                existingLoansTotalAmount: renegotiateLoans
                  ? totalExistingDebts || 0
                  : 0,
                phone,
                email,
              },
            },
            {},
          )
          .toPromise();
        if (data?.loanPrediction?.__typename === 'RangedLoanPredictionResult') {
          const {
            likelihood,
            minInterestRate,
            maxInterestRate,
          } = loanPredictionAttributes(
            data.loanPrediction,
            props.loanApplication.appliedAmount,
          );

          ThirdPartyTracking.loanPredictionEngagement(
            props.app.memberId,
            'loan prediction created',
            props.loanApplication.appliedAmount,
            likelihood,
            minInterestRate,
            maxInterestRate,
          );

          navigate('/app');
        } else if (
          data?.loanPrediction?.__typename === 'LoanPredictionQuotaExceeded'
        ) {
          setShowQuotaDialog(true);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  // Mobile BankId Auth

  const isInstagramOnIOS =
    typeof window !== 'undefined' &&
    window.navigator.userAgent.toLowerCase().includes('instagram') &&
    isIOS;
  const isMobile = useIsMobile();

  const [loginState, setLoginState] = useState<MobileBankIdAuthState>(
    'not_started',
  );

  const [initAuthResult, initAuth] = useInitAuthMutation();
  const [authStatus, getAuthStatus] = useGetAuthStatusQuery({
    variables: { authJwt: initAuthResult.data?.postAuth.authJwt },
    pause: true,
  });

  const [bankIdAppStarted, setBankIdAppStarted] = useState(false);

  let redirect = 'null';
  if (isIOS) {
    // Hack alert!
    // In order to prevent Safari from reloading the page when redirecting from BankId the nonexisting anchor is added to the redirect link.
    // The hack was found on SO: https://stackoverflow.com/a/24786670/488035
    redirect = encodeURIComponent(`${window.location.href}#bankId`);
  }

  useEffect(() => {
    if (
      (loginState !== 'started' && loginState !== 'started_mobile') ||
      !initAuthResult.data ||
      authStatus.fetching
    ) {
      return;
    }

    if (
      loginState === 'started_mobile' &&
      !bankIdAppStarted &&
      initAuthResult.data?.postAuth.launchInfo.autoStartToken
    ) {
      setBankIdAppStarted(true);
      const query = `?autostarttoken=${initAuthResult.data?.postAuth.launchInfo.autoStartToken}&redirect=${redirect}`;
      const bankIdUrl = `bankid:///${query}`;
      window.location.replace(bankIdUrl);
    }

    const timerId = setTimeout(() => {
      getAuthStatus({ requestPolicy: 'network-only' });
    }, 1000);

    return () => clearTimeout(timerId);
  }, [initAuthResult.data, authStatus.fetching, getAuthStatus]);

  useEffect(() => {
    const response = authStatus.data?.getAuth;
    if (
      response &&
      response.status == AuthStatus.Success &&
      response.rockerJwt
    ) {
      localStorage.setItem('token', response.rockerJwt);
      isJwtValid();
      props.setLoggedIn();
      setLoginState('completed');
    }
  }, [authStatus.data]);

  useEffect(() => {
    const memberId = customClaims.data?.isJwtValid.user.member_id;
    if (memberId) {
      props.setMemberId(memberId);
    }
  }, [customClaims.data?.isJwtValid.user.member_id]);

  useEffect(() => {
    if (
      initAuthResult.error ||
      authStatus.error ||
      authStatus.data?.getAuth.status === AuthStatus.Failure
    ) {
      setLoginState('canceled'); // TODO handle errors better when we have design for it
    }
  }, [initAuthResult.error, authStatus.error, authStatus.data]);

  const qrAuthData =
    authStatus.data?.getAuth.qrAuthData ||
    initAuthResult.data?.postAuth.launchInfo.qrAuthData;

  const startAuth = () => {
    window.scrollTo(0, 0);
    const newErrors = validateForm();
    setErrors(newErrors);
    if (!Object.values(newErrors).includes(true)) {
      setLoginState('started');
      initAuth({});
    }
  };

  const startMobileAuth = () => {
    window.scrollTo(0, 0);
    const newErrors = validateForm();
    setErrors(newErrors);
    if (!Object.values(newErrors).includes(true)) {
      setLoginState('started_mobile');
      initAuth({});
    }
  };

  ///// End of Mobile BankID auth

  useEffect(() => {
    if (
      loginState === 'completed' &&
      props.app.loggedIn &&
      props.app.memberId
    ) {
      onSubmit(); // run the calculation for the likelihoods
    }
  }, [loginState, props.app.loggedIn, props.app.memberId]);

  if (showQuotaDialog) {
    return (
      <Dialog
        open={showQuotaDialog}
        onClose={() => setShowQuotaDialog(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Du har gjort maximalt antal uträkningar för senaste 7 dagarna.
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowQuotaDialog(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
        <div>Laddar</div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {loginState !== 'started' && (
        <>
          <div className={styles.formSegment}>
            <div className={styles.formSegmentTitle}>Hur bor du?</div>
            <TextField
              label="Boendeform"
              select
              className={styles.selectinput}
              error={errors.accommodationType && !accommodationType}
              value={accommodationType}
              onChange={ev => {
                typeof ev.target.value === 'string' &&
                isSomeEnum(AccomodationType, ev.target.value)
                  ? props.updateAccommodation(ev.target.value)
                  : null;
                setErrors(validateSingleField('accommodationType'));
              }}
              helperText={
                errors.accommodationType && !accommodationType
                  ? 'Välj boendeform'
                  : ''
              }
              variant="filled"
            >
              {accommodations.map(o => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Civilstånd"
              select
              className={styles.selectinput}
              error={errors.maritalStatus && !maritalStatus}
              value={maritalStatus}
              onChange={ev => {
                typeof ev.target.value === 'string' &&
                isSomeEnum(MaritalStatus, ev.target.value)
                  ? props.updateMaritalStatus(ev.target.value)
                  : null;
                setErrors(validateSingleField('maritalStatus'));
              }}
              helperText={
                errors.maritalStatus && !maritalStatus ? 'Välj civilstånd' : ''
              }
              variant="filled"
            >
              {maritalStatusOptions.map(o => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </TextField>
            <div
              className={styles.toggleContainer}
              style={{ marginTop: '1rem' }}
            >
              <label>
                <div>Har du barn boende hemma?</div>
                <YesNoInput
                  className={styles.toggle}
                  onChange={(_evt, value) => {
                    setChildren(
                      value ? Children.OneChild : Children.NoChildren,
                    );
                  }}
                  value={children !== Children.NoChildren}
                />
              </label>
            </div>
          </div>
          <div className={styles.formSegment}>
            <div className={styles.formSegmentTitle}>
              Vad är din sysselsättning?
            </div>
            <TextField
              label="Sysselsättning"
              select
              className={styles.selectinput}
              error={errors.occupationType && !occupationType}
              value={occupationType}
              onChange={ev => {
                typeof ev.target.value === 'string' &&
                isSomeEnum(OccupationTypeName, ev.target.value)
                  ? props.updateOccupation(ev.target.value)
                  : null;
                setErrors(validateSingleField('occupationType'));
              }}
              helperText={
                errors.occupationType && !occupationType
                  ? 'Välj sysselsättning'
                  : ''
              }
              variant="filled"
            >
              {occupations
                .filter(v => !['student', 'unemployed'].includes(v.value))
                .map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              id="monthlyIncome"
              label="Månadsinkomst före skatt (kr / mån)"
              className={styles.textinput}
              error={errors.monthlyIncome}
              type="text"
              InputProps={{ inputProps: { pattern: '\\d+' } }}
              value={monthlyIncome && formatMoney(monthlyIncome)}
              onChange={e => {
                const value = parseInt(e.target.value.replaceAll(/\D/g, ''));
                props.updateMonthlySalary(isNaN(value) ? '' : value);
              }}
              onBlur={e => setErrors(validateSingleField('monthlyIncome'))}
              helperText={
                errors.monthlyIncome
                  ? 'Ange din månadsinkomst (minst 10 000 kr, max 140 000 kr)'
                  : ''
              }
              variant="filled"
            />
          </div>

          <div className={styles.formSegment}>
            <div className={styles.toggleContainer}>
              <label>
                <div>Har du betalningsanmärkning?</div>
                <YesNoInput
                  className={styles.toggle}
                  onChange={(_evt, value) => {
                    setPaymentRemark(value);
                  }}
                  value={paymentRemark}
                />
              </label>
            </div>
            <div className={styles.toggleContainer}>
              <label>
                <div>Har du privatlån sedan tidigare?</div>
                <YesNoInput
                  className={styles.toggle}
                  onChange={(_evt, value) => {
                    props.updateRenegotiateLoans(value);
                  }}
                  value={props.loanApplication.renegotiateLoans}
                />
              </label>
            </div>
            {props.loanApplication.renegotiateLoans && (
              <>
                <TextField
                  id="totalExistingDebts"
                  label="Summa av alla privatlån i kr"
                  className={styles.textinput}
                  error={errors.totalExistingDebts}
                  type="text"
                  InputProps={{
                    inputProps: { min: 0, max: 1_000_000, pattern: '\\d+' },
                  }}
                  value={totalExistingDebts && formatMoney(totalExistingDebts)}
                  onChange={e => {
                    if (e.target.value === '' || e.target.value == null) {
                      setTotalExistingDebts(null);
                    } else {
                      const value =
                        parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                      setTotalExistingDebts(isNaN(value) ? 0 : value);
                    }
                  }}
                  onBlur={e => {
                    const value =
                      parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                    if (
                      renegotiateLoansAmount === null &&
                      !isNaN(value) &&
                      value > 0
                    ) {
                      props.updateRenegotiateLoansAmount(
                        value <= 600_000 ? value : 600_000,
                      );
                      setRenegotiateLoansAmount(
                        value <= 600_000 ? value : 600_000,
                      );
                    }
                    setErrors(validateSingleField('totalExistingDebts'));
                  }}
                  helperText={
                    errors.totalExistingDebts
                      ? 'Beloppet måste vara maximalt 1 000 000 kr och inte mindre än "Privatlån i kr jag vill omförhandla"'
                      : ''
                  }
                  variant="filled"
                />
                <TextField
                  id="renegotiateLoansAmount"
                  label="Privatlån i kr jag vill omförhandla"
                  className={styles.textinput}
                  error={errors.renegotiateLoansAmount}
                  type="text"
                  InputProps={{
                    inputProps: { min: 0, max: 600_000, pattern: '\\d+' },
                  }}
                  value={
                    renegotiateLoansAmount &&
                    formatMoney(renegotiateLoansAmount)
                  }
                  onChange={e => {
                    if (e.target.value === '' || e.target.value == null) {
                      setRenegotiateLoansAmount(null);
                    } else {
                      const value =
                        parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                      setRenegotiateLoansAmount(isNaN(value) ? 0 : value);
                    }
                  }}
                  onBlur={e => {
                    const value =
                      parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                    if (value >= 10_000) {
                      props.updateRenegotiateLoansAmount(value);
                    }
                    setErrors(validateSingleField('renegotiateLoansAmount'));
                    setErrors(validateSingleField('totalExistingDebts'));
                  }}
                  helperText={
                    errors.renegotiateLoansAmount
                      ? 'Beloppet måste vara maximalt 600 tkr'
                      : ''
                  }
                  variant="filled"
                />
              </>
            )}
          </div>

          <TextField
            id="phone"
            label="Mobiltelefon"
            className={styles.textinput}
            error={errors.phone && !isValidPhoneNumber(phone)}
            type="tel"
            value={asYouType.input(phone)}
            onChange={e => props.updatePhone(e.target.value)}
            onBlur={e => setErrors(validateSingleField('phone'))}
            helperText={
              errors.phone && !isValidPhoneNumber(phone)
                ? 'Ange en giltig telefonnummer'
                : ''
            }
            variant="filled"
          />

          <TextField
            id="email"
            label="E-post"
            className={styles.textinput}
            error={errors.email && !isValidEmail(email)}
            type="email"
            value={email}
            onChange={e => props.updateEmail(e.target.value)}
            onBlur={e => setErrors(validateSingleField('email'))}
            helperText={
              errors.email && !isValidEmail(email)
                ? 'Ange en giltig epostadress'
                : ''
            }
            variant="filled"
          />

          <Markdown className={styles.fineprint} openLinksInNewTab>
            För information om hur vi hanterar din data, se vår
            [personuppgiftspolicy](https://rocker.com/personuppgiftspolicy/).
          </Markdown>
        </>
      )}

      {token && !tokenIsExpired(token) ? (
        <PrimaryButton
          onClick={onSubmit}
          type="button"
          disabled={loading}
          className={styles.primaryButton}
          theme="white"
        >
          Visa mitt ränteindex
        </PrimaryButton>
      ) : (
        <div className={styles.main}>
          <Markdown className={styles.informationPanel}>
            {`**Ingen kreditupplysning eller ansökan sker!**\n\n\n\nVi räknar ut ränteindex och ränta genom maskininlärning utifrån faktiska låneansökningar som skickats till våra samarbetsbanker.
            Vår modell har testats och ger att resultatet stämmer till ${defaultVersionAccuracy} %. Modellen kalibreras löpande och det skedde senast ${defaultVersionReleaseDate}.
            Rocker kan inte garantera att det belopp och den ränta som visas är det erbjudande du får vid en riktig ansökan, då det kan påverkas av en faktisk kreditupplysning.`}
          </Markdown>
          {loginState !== 'started' &&
            (isMobile && !isInstagramOnIOS ? (
              <>
                <PrimaryButton
                  onClick={startMobileAuth}
                  className={styles.primaryButton}
                  icon="BankId"
                  theme="white"
                >
                  Visa mitt ränteindex
                </PrimaryButton>
                <Button
                  variant="text"
                  className={styles.secondaryButton}
                  onClick={startAuth}
                >
                  BankID på annan enhet
                </Button>
              </>
            ) : (
              <PrimaryButton
                onClick={startAuth}
                className={styles.primaryButton}
                icon="BankId"
                theme="white"
              >
                Visa mitt ränteindex
              </PrimaryButton>
            ))}
          {loginState === 'started' && (
            <div className={styles.bankIdContainer}>
              <Typography variant="h2">Logga in med Mobilt BankID</Typography>
              {qrAuthData && (
                <>
                  <QRCode value={qrAuthData} />
                </>
              )}
              {isMobile && isInstagramOnIOS ? ( // Hack for the ig in-app browser
                <div style={{ marginTop: '1rem' }}>
                  <a
                    className={styles.secondaryButton}
                    href={`https://app.bankid.com/?autostarttoken=${initAuthResult.data?.postAuth.launchInfo.autoStartToken}&redirect=`}
                  >
                    Öppna bankid på denna enhet
                  </a>
                </div>
              ) : (
                <div>
                  <Button
                    variant="text"
                    className={styles.secondaryButton}
                    onClick={() => setLoginState('canceled')}
                  >
                    Avbryt inloggning
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: {
  loanApplication: LoanApplicationReduxType;
  app: AppState;
}) => ({
  loanApplication: state.loanApplication,
  app: state.app,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...loanApplicationSlice.actions,
      authStarted: null,
      authCompleted: null,
      setLoggedIn: () => dispatch(appSlice.actions.setLoggedIn(true)),
      setMemberId: (memberId: string) =>
        dispatch(appSlice.actions.setMemberId(memberId)),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanPredictor);
