import { useEffect, useState } from 'react';
import {
  LoanPredictionModelVersionDetails,
  useLoanPredictionModelVersionQuery,
} from '../../graphql/generated';

type CacheEntry<A> = {
  timestamp: number;
  content: A;
};

function isCacheEntry<A>(
  obj: unknown,
  p: (a: unknown) => a is A,
): obj is CacheEntry<A> {
  return (
    typeof obj === 'object' &&
    'timestamp' in obj &&
    'content' in obj &&
    p(obj.content)
  );
}

function isLoanPredictionModelVersionDetails(
  obj: unknown,
): obj is LoanPredictionModelVersionDetails {
  return typeof obj === 'object' && 'accuracy' in obj && 'releaseDate' in obj;
}

const useLoanPredictionModelVersionWithCache: () =>
  | LoanPredictionModelVersionDetails
  | undefined = () => {
  const [
    loanPredictorModelVersion,
    getDefaultModelVersion,
  ] = useLoanPredictionModelVersionQuery({ pause: true });

  const cacheTTL = 15 * 60 * 1000;
  const key = 'loan-predictor-default-version-details';

  const [version, setVersion] = useState<
    LoanPredictionModelVersionDetails | undefined
  >(() => {
    try {
      const cacheEntry = JSON.parse(sessionStorage.getItem(key));
      if (
        isCacheEntry<LoanPredictionModelVersionDetails>(
          cacheEntry,
          isLoanPredictionModelVersionDetails,
        )
      ) {
        // Check cache entry expiration
        return cacheEntry.timestamp + cacheTTL < Date.now()
          ? undefined
          : cacheEntry.content;
      }
    } catch {
      // Cache miss
      return undefined;
    }
  });

  useEffect(() => {
    if (!version) {
      // Start process to refill the cache
      getDefaultModelVersion();
    }
  }, [version]);

  useEffect(() => {
    if (loanPredictorModelVersion.data) {
      const result = loanPredictorModelVersion.data.loanPredictionModelVersion;
      setVersion(result);
      const cacheEntry: CacheEntry<LoanPredictionModelVersionDetails> = {
        timestamp: Date.now(),
        content: result,
      };
      // Cache filling
      sessionStorage.setItem(key, JSON.stringify(cacheEntry));
    }
  }, [loanPredictorModelVersion, loanPredictorModelVersion.data]);

  return version;
};

export default useLoanPredictionModelVersionWithCache;
