import React from 'react';

import Layout from '@components/Layout/Layout';
import NyhetPink from '@icons/2.0/illustrations/NyhetPink.svg';
import NoCreditCheck from '@icons/2.0/illustrations/NoCreditCheck.svg';
import LoanPredictionExample from '@icons/2.0/illustrations/LoanPredictionExample.svg';

import Container from '@components/Container';
import TextHero from '@components/TextHero';
import { PrivatlanQuery } from '../../graphql-types';
import * as styles from './ranteindex.module.scss';
import '@src/sentry'; // Enable Sentry monitoring for errors
import { useMediaQuery } from 'react-responsive';
import LoanPredictor from '@src/components/LoanPredictor/LoanPredictor';
import useSearchParam from '@src/hooks/useSearchParam.hook';

type LoanPredictionPageProps = {
  data: PrivatlanQuery;
};

const LoanPredictionFormPage = ({ data }: LoanPredictionPageProps) => {
  const adtractionId = useSearchParam('at_gd');

  if (adtractionId) {
    localStorage.setItem('at_gd', adtractionId);
  }

  const adwordsId = useSearchParam('gclid');
  if (adwordsId) {
    localStorage.setItem('gclid', adwordsId);
  }

  const isSmallScreen = useMediaQuery({ query: '(max-width: 896px)' });

  return (
    <Layout path="/ranteindex" theme="soft">
      <div className={styles.wrapper}>
        <Container className={styles.loanPredictionWrapper}>
          <div className={styles.loanPredictionInfoContainer}>
            <TextHero
              className={styles.textHero}
              leftAligned
              size="large"
              title="Kolla ditt Ränteindex!"
              subtitle={`
## Testa och se om du kan samla dina lån och krediter till lägre ränta
`}
              LeftIcon={NyhetPink}
            />
            {!isSmallScreen && (
              <div className={styles.noCreditCheck}>
                <NoCreditCheck />
              </div>
            )}
            <ul>
              <li>Kostnadsfritt</li>
              <li>Avser lån upp till 600 000 kr</li>
              {!isSmallScreen && (
                <li>
                  Följ hur din möjlighet till lån och förväntad ränta utvecklas
                  över tid
                </li>
              )}
            </ul>
            <div className={styles.loanPredictionExample}>
              <LoanPredictionExample />
            </div>
          </div>
          <div className={styles.loanPredictionFormContainer}>
            {isSmallScreen && (
              <div className={styles.noCreditCheck}>
                <NoCreditCheck />
              </div>
            )}
            <LoanPredictor />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default LoanPredictionFormPage;
