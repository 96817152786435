// extracted by mini-css-extract-plugin
export var applyStepsBadge = "ranteindex-module--applyStepsBadge--9690f";
export var callCard = "ranteindex-module--callCard--785ab";
export var cardsWrapper = "ranteindex-module--cardsWrapper--07960";
export var freeToApply = "ranteindex-module--freeToApply--613eb";
export var lanWrapper = "ranteindex-module--lanWrapper--69ac6";
export var loanPredictionExample = "ranteindex-module--loanPredictionExample--88cb4";
export var loanPredictionFormContainer = "ranteindex-module--loanPredictionFormContainer--3a7e2";
export var loanPredictionInfoContainer = "ranteindex-module--loanPredictionInfoContainer--b1060";
export var loanPredictionWrapper = "ranteindex-module--loanPredictionWrapper--d0d39";
export var noCreditCheck = "ranteindex-module--noCreditCheck--a9451";
export var phoneSvg = "ranteindex-module--phoneSvg--870a7";
export var textHero = "ranteindex-module--textHero--49e5c";
export var wrapper = "ranteindex-module--wrapper--1ca7c";