// extracted by mini-css-extract-plugin
export var bankIdContainer = "LoanPredictor-module--bankIdContainer--a31a7";
export var button = "LoanPredictor-module--button--24d15";
export var fineprint = "LoanPredictor-module--fineprint--44678";
export var formSegment = "LoanPredictor-module--formSegment--c5ae6";
export var formSegmentTitle = "LoanPredictor-module--formSegmentTitle--43ebc";
export var formWrapper = "LoanPredictor-module--formWrapper--9cbee";
export var informationPanel = "LoanPredictor-module--informationPanel--39c92";
export var likelihoodContainer = "LoanPredictor-module--likelihoodContainer--64fec";
export var loadingContainer = "LoanPredictor-module--loadingContainer--e1359";
export var primaryButton = "LoanPredictor-module--primaryButton--41d43";
export var secondaryButton = "LoanPredictor-module--secondaryButton--56c0a";
export var selectinput = "LoanPredictor-module--selectinput--a3aea";
export var textinput = "LoanPredictor-module--textinput--2accf";
export var textinputWrapper = "LoanPredictor-module--textinputWrapper--09c7a";
export var toggle = "LoanPredictor-module--toggle--dfbbf";
export var toggleContainer = "LoanPredictor-module--toggleContainer--1bb08";
export var togglesGroup = "LoanPredictor-module--togglesGroup--bf078";
export var wrapper = "LoanPredictor-module--wrapper--43a95";